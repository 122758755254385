$(function() {
    $('html').removeClass('no-js');

    var isIe = JSH.isIe();
    if(isIe != false) {
        $('html').addClass(isIe.name + ' ' + isIe.name+'-'+isIe.version);
    }
});


$(window).on('load', function() {
    $('.frame-type-wvier_hero').find('.hero-text').addClass('enter-animation');
    $('.frame-type-wvier_hero').find('.hero-img').addClass('enter-animation-img');

    if($('.fact-item').length > 0) {
        $(window).on('scroll resize', function() {
            delayTime = 0;
            $('.fact-item:not(.fact-animated)').each(function () {
                var $elem = $(this);
                if(JSH.isBottomScrolledIntoView($(this), true)) {
                    delayTime = delayTime + 200;
                    if(delayTime > 600) {
                        delayTime = 0;
                    }
                    setTimeout(function(){
                        $elem.addClass('fact-animated');
                    }, delayTime);
                }else {
                    delayTime = 0;
                }
            });
        });
    }
});



