$(function() {


    if($('.mfp-image').length > 0 && JSH.getLanguage() == 'de') {
        //Translate magnific popup
        $.extend(true, $.magnificPopup.defaults, {
            tClose: 'Schließen (Esc)', // Alt text on close button
            tLoading: 'Lädt...', // Text that is displayed during loading. Can contain %curr% and %total% keys
            gallery: {
                tPrev: 'Zurück (linke Pfeiltaste)', // Alt text on left arrow
                tNext: 'Weiter (rechte Pfeiltaste)', // Alt text on right arrow
                tCounter: '%curr% von %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' // Error message when image could not be loaded
            },
            ajax: {
                tError: '<a href="%url%">Der Inhalt</a> konnte nicht geladen werden.' // Error message when ajax request failed
            }
        });
    }

    $('.mfp-gallery').each(function () {
        $(this).magnificPopup({
            delegate: 'a.mfp-image',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            },
            gallery: {
                enabled: true,
                arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><i class="fal fa-angle-%dir% mfp-prevent-close"></i></button>', // markup of an arrow button
            }
        });
    });

});
