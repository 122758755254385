$(function() {

    /* change navbar class depending on first content Element */
    var $firstElement = $('#main').find('.frame').first();
    var navColor = 'dark';
    var $headerVideoSlider = $('#main').find('.herovideoslider-wrapper').first();


    if(!$firstElement.hasClass('frame-background-dark')) {
        $('.navbar.fixed-top').removeClass('navbar-dark').addClass('navbar-light');
        navColor = 'light';
    }

    if($headerVideoSlider.length == 1) {
        $('.navbar.fixed-top').removeClass('navbar-light').addClass('navbar-dark');
        navColor = 'dark';
    }

    /* change navbar class on scroll */
    if(navColor == 'dark') {
        if ($(this).scrollTop() > 80) {
            $('.navbar.fixed-top').removeClass('navbar-dark').addClass('navbar-light');
        }

        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 80) {
                $('.navbar.fixed-top').removeClass('navbar-dark').addClass('navbar-light');
            } else {
                $('.navbar.fixed-top:not(.navbar-open)').removeClass('navbar-light').addClass('navbar-dark');
            }
        });
    }

    headsUp.init('.navbar.fixed-top');

    $('#navbarMain').on('show.bs.collapse', function () {
        $('.navbar.fixed-top').removeClass('navbar-dark').addClass('navbar-light');
        $('.navbar.fixed-top').addClass('navbar-open');
        $('html').addClass('main-navbar-open');
    });

    $('#navbarMain').on('hide.bs.collapse', function () {
        $('.navbar.fixed-top').removeClass('navbar-open');
        $('html').removeClass('main-navbar-open');
        if(navColor == 'dark' && $(window).scrollTop() <= 150) {
            $('.navbar.fixed-top').removeClass('navbar-light').addClass('navbar-dark');
        }
    });

    $('.dropdown-close-btn').on('click', function () {
        if($('#' + $(this).data('close')).length) {
            $('#' + $(this).data('close')).dropdown('hide');
        }
    });

    /**
     * Solution to show dropdowns on hover
     */
    function navbarPointerOver($element) {
        if($('.navbar-toggler').is(':hidden') && !$element.hasClass('open')) {
            $element.parent().parent().find('li').removeClass('show');
            $element.addClass('show');
            $element.find('> .dropdown-toggle').attr("aria-expanded", "true");
            $element.find('> .dropdown-menu').addClass('show');
        }
    }
    function navbarPointerLeave($element) {
        if ($('.navbar-toggler').is(':hidden')) {
            $element.removeClass('show');
            $element.find('> .dropdown-toggle').attr("aria-expanded", "false");
            $element.find('> .dropdown-menu').removeClass('show');
        }
    }
    $(document).on('pointerover', 'li.dropdown-hover', function (e) {
        if (e.originalEvent.pointerType === "mouse") {
            navbarPointerOver($(this));
        }
    });
    $(document).on('mouseenter', 'li.dropdown-hover', function () {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            navbarPointerOver($(this));
        }
    });
    $(document).on('pointerleave', 'li.dropdown-hover', function (e) {
        if (e.originalEvent.pointerType === "mouse") {
            navbarPointerLeave($(this));
        }
    });
    $(document).on('mouseleave', 'li.dropdown-hover', function () {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            navbarPointerLeave($(this));
        }
    });
});
